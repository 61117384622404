var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Administrar Usuarios")]),_c('b-tabs',{attrs:{"content-class":"mt-3"}},[(_vm.user.is_superuser)?_c('b-tab',{attrs:{"title":"Administrar Institución"}},[_c('InstitutionCard',{attrs:{"allows_crud":_vm.allows_crud}})],1):_vm._e(),_c('b-tab',{attrs:{"title":"Administrar Roles"}},[_c('GroupNameAssignment',{attrs:{"allows_crud":_vm.allows_crud},on:{"created":_vm.slotCreatedRole,"updated":_vm.slotUpdatedRole,"delete":_vm.slotDeleteRole}})],1),_c('b-tab',{attrs:{"title":"Administrar Permisos de Usuarios","active":""}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_cycle'),expression:"'mesh.add_cycle'"}],attrs:{"tooltip_text":`Agregar Usuario`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-user-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.users,"fields":_vm.fields,"thead-class":"b-card-style","stacked":"md","show_pagination":true,"search_filter":true,"selection_mode":true,"allows_crud":_vm.allows_crud,"filterCustom":_vm.filterCustom,"display_id":'admin-user',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(avatar)",fn:function(data){return [_c('div',[_c('b-avatar',{attrs:{"id":`user-avatar-${data.item.id}`,"size":"2rem","text":`${
                data.item.first_name.charAt(0) + data.item.last_name.charAt(0)
              }`,"variant":"info"}})],1)]}},{key:"cell(is_professor)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getRole(data.item))+" ")]}},{key:"cell(actions)",fn:function(data){return [(!_vm.allows_crud)?_c('div',{staticClass:"noprint"},[_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() => _vm.openUserInfoModal(data.item.id),"tooltip_text":`Información del Usuario`,"icon":'eye'}}),_c('b-modal',{attrs:{"id":`user-info-modal-${data.item.id}`,"title":"Información del Usuario","size":"lg","hide-footer":""}},[_c('UserForm',{attrs:{"User":data.item,"IdentifierTypes":_vm.identifier_types,"Roles":_vm.roles,"allows_crud":_vm.allows_crud}})],1)],1):_vm._e(),(_vm.allows_crud)?_c('div',[(data.item.user_data == null || data.item.user_data)?_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() => _vm.sendMail(data.item),"tooltip_text":`Enviar Correo al Usuario`},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(
                    data.item.user_data == null ||
                    (data.item.user_data &&
                      !data.item.user_data.creation_email_sent)
                  )?[_c('b-icon',{attrs:{"icon":"envelope","scale":"1"}})]:(
                    data.item.user_data &&
                    data.item.user_data.creation_email_sent
                  )?[_c('b-icon',{attrs:{"icon":"envelope-open","scale":"1"}})]:_vm._e()]},proxy:true}],null,true)}):_vm._e(),_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() => _vm.$bvModal.show(`edit-user-modal-${data.item.id}`),"tooltip_text":`Editar Usuario`,"icon":'square'}}),_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() =>
                  _vm.$bvModal.show(`edit-user-permission-modal-${data.item.id}`),"tooltip_text":`Administrar Permisos para el Usuario.`,"disabled_button":!(
                  ![6, 7].includes(data.item.groups[0]) &&
                  !data.item.is_superuser &&
                  data.item.groups.length > 0
                )},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('b-icon',{attrs:{"icon":"person-lines-fill"}})]},proxy:true}],null,true)}),_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() =>
                  _vm.$bvModal.show(`copy-user-permission-modal-${data.item.id}`),"tooltip_text":`Copiar Permisos`,"disabled_button":!(
                  ![6, 7].includes(data.item.groups[0]) &&
                  !data.item.is_superuser &&
                  data.item.groups.length > 0 &&
                  _vm.filterUserByGroups(data.item).length > 0
                )},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"files"}})]},proxy:true}],null,true)}),_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() => _vm.askForDeleteUser(data.item.id),"tooltip_text":`${
                _vm.user.id != data.item.id
                  ? 'Eliminar Usuario'
                  : 'No puedes eliminar el usuario con el que ingresaste'
              }`,"disabled_button":_vm.user.id == data.item.id,"icon":'trash'}})],1):_vm._e(),_c('b-modal',{attrs:{"id":`edit-user-permission-modal-${data.item.id}`,"title":"Editar Permisos","size":"xl","hide-footer":""}},[_c('AccessForm',{attrs:{"User":data.item,"IdentifierTypes":_vm.identifier_types,"Roles":_vm.roles,"allows_crud":_vm.allows_crud}})],1),_c('b-modal',{attrs:{"id":`copy-user-permission-modal-${data.item.id}`,"title":"Copiar Permisos","size":"md","hide-footer":""}},[_c('b-form-group',{attrs:{"label":"Usuario","label-for":"name-input","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-select',{attrs:{"id":"name-select","name":"name-select","value-field":"id","text-field":"name"},model:{value:(_vm.selected_user),callback:function ($$v) {_vm.selected_user=$$v},expression:"selected_user"}},_vm._l((_vm.filterUserByGroups(data.item)),function(user){return _c('b-form-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" - "+_vm._s(user.email)+" ")])}),1)],1),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","disabled":!_vm.selected_user},on:{"click":function($event){return _vm.copyTableAccess(_vm.selected_user, data.item.id)}}},[_vm._v("Guardar")])],1)],1),_c('b-modal',{attrs:{"id":`edit-user-modal-${data.item.id}`,"title":"Editar Usuario","size":"lg","hide-footer":""}},[_c('UserForm',{attrs:{"User":data.item,"IdentifierTypes":_vm.identifier_types,"Roles":_vm.roles,"allows_crud":_vm.allows_crud},on:{"updated":_vm.slotCloseModal}})],1)]}}])}),_c('div',[_c('b-modal',{attrs:{"id":"new-user-modal","title":"Crear Usuario","size":"lg","hide-footer":""}},[_c('UserForm',{attrs:{"IdentifierTypes":_vm.identifier_types,"Roles":_vm.roles,"allows_crud":_vm.allows_crud},on:{"created":_vm.slotCreatedUser}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }